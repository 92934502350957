import React from 'react'
import Button from './Button.js'
import logo from '../images/logo.svg'


export default function Navbar() {

  let Links = [
    {no:"1.", name:"About", link:"/#about"},
    {no:"2.", name:"Experience", link:"/#experience"},
    {no:"3.", name:"Projects", link:"/#projects"},
    {no:"4.", name:"Contact", link:"/#contact"}
  ];

  let [open,setOpen] = React.useState(false);

  function toggle(){
    setOpen(!open)
  }

  return (
    <div className='w-full top-0 left-0 font-spaceMono z-10'>

      <div className='md:flex items-center  justify-between bg-gradient-to-r from-[#234E70] to-[#102738] py-4 md:px-10 px-7'>

        <div className='item-center'>
          <img className='w-14' alt="Logo" src={logo}/>
        </div>

        <div onClick={toggle} className='text-3xl text-white absolute right-8 top-6 md:hidden'>
          <ion-icon name={open ? 'close':'menu'}></ion-icon>
        </div>

        <ul className={`md:flex md:items-center md:pb-0 md:static md:z-auto md:w-auto md:pl-0
        pb-12 absolute  right-0 w-6/12 bg-[#102738] md:bg-transparent pl-9 transition-all duration-500 ease-in ${open ? 'top-20':'top-[-490px]'} `} >
          
          {
            Links.map(link => (
              <li key={link.no} className='md:ml-8 text-md md:my-0 my-7'>
                <a href={link.link}>
                  <span className='text-lime-100 '>{link.no}</span>
                  <span className='text-stone-400 hover:text-gray-100 duration-500'>{link.name}</span>
                </a>
              </li>
            ))
          }

         <a href='https://drive.google.com/file/d/1R6tPpdAoU-L5UjSD2Mh8goZmezMjIcBQ/view?usp=sharing' target='_blank' rel="noopener noreferrer"><Button>RESUME</Button></a>

        </ul>
        
        
      </div>

    </div>
  
  )
}
