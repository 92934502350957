import React from 'react'
import profileHover from '../images/profile-hover.png'
import profileNoHover from '../images/profile-nohover.png'

export default function About() {

  const [profileImg, setProfileImg] = React.useState(false)

  function switchImage(){
    setProfileImg (toggleImg => !toggleImg)
  }

  return (
    <section className='mt-40 xl:mt-48 mb-16'>
        <div className="flex mt-14 items-center justify-center w-full mb-32">
            <hr className="w-52 ml-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="px-3 text-xs md:text-lg font-spaceMono font-medium 
            text-stone-400 dark:text-white dark:bg-gray-900">
                <span id='about' className='text-lime-100'>1.</span> A Little bit about me.</span>
            <hr className=" w-52 mr-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
        </div>
        <div className="flex justify-around gap-8 flex-wrap px-20 md:flex-nowrap xl:px-48">
          <div onMouseEnter={switchImage} onMouseLeave={switchImage}>
            {profileImg ? <img src={profileHover} alt="" /> : <img src={profileNoHover} alt="" /> }
          </div>
          <div className='font-ubuntu text-stone-200 text-sm'>
              <p>I have completed my bachelor’s in <span className='text-lime-100 font-medium'>computer science and 
            engineering </span>back in India and did my post-graduation 
            in <span className='text-lime-100 font-medium'>AI</span> . As I was exposed to computers and tech at a 
            very early age, I was fascinated how digital designs and tech can make life easier. 
            </p>
        <br/>
            <p>What I feel is, as a designer and developer you have the superpowers 
            to sketch the ideas for people and bring them to life with some great designs and coding. And that’s what pushes me to learn 
            and improve my skills every day. During my work at Maxffort and Envelor 
            I got a chance to design and develop some great digital designs and web applications. </p>
        <br/>
            <p>The world of <span className='text-lime-100 font-medium'>Data and AI </span>was opened to me during my post graduation 
            studies. My team-mates and I did some amazing projects which showcased 
            the true potential of data around us and how it could help grow organisations.
            During my leisure hours, I do some digging on latest tech gadgets and research on trending designs. 
            </p>
          </div>
        </div>

    </ section>
  )
}

