import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import About from "./components/About";
import Experience from "./components/Experience";
import webData from './webData.js'
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

function App() {

  const [loading,setLoading] = React.useState(false)
  
  React.useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    },3000)
  },[])
  
  return (
    <div>
      {loading ? 
        <div className="loadingIcon">
            <BeatLoader
            color={"#faf8be"}
            loading={loading}
            size={30}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
        :
        <>
          <Navbar />
          <Hero />
          <About />
          <Experience text={webData}/>
          <Projects text={webData}/>
          <Contact />
          <Footer />
        </>
      }
      
    </div>
  );
}

export default App;
