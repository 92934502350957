const exportedObject = {
    "success": true,
    "data": {
        projectData: [
            {
                id: 1,
                imageUrl:"lines-and-colors-bg.png",
                title:"Lines & Colors",
                description:"This platform is for the customers to order high-quality printed materials from the comfort of their own home. The website features a user-friendly interface that makes it easy to upload and customize designs, choose print options, and place orders.",
                skills: ["React","Material UI","JavaScript","Firebase"],
                allDataUrl:"https://linesandcolors.ca/"
            },
            {
                id: 2,
                imageUrl:"weatherly-app-bg.jpeg",
                title:"Weatherly",
                description:"Weatherly is a simple and elegant web application that lets you check the current weather conditions and forecast for any predefined cities listed in Canada.",
                skills: ["React","Redux","Weather API"],
                allDataUrl:"https://weather-app-5bd5e.firebaseapp.com/"
            },
            {
                id: 3,
                imageUrl:"eldho-portfolio-bg.png",
                title:"Eldho's Portfolio",
                description:"The website serves as a comprehensive portfolio, showcasing the individual's skills, experience, and achievements in the tech industry. The website features a clean and modern design, highlighting the professional's technical expertise and past projects.",
                skills: ["React","TailwindCSS"],
                allDataUrl:"https://www.el2e10.com/"
            },
            {
                id: 4,
                imageUrl:"walk-and-trot-bg.png",
                title:"Walk & Trot",
                description:"The website provides a platform for individuals to learn the art of horse riding from experienced trainers. Website includes detailed information about the riding classes, instructors, and available horses, providing a comprehensive overview for potential students and they also provide leisure riding for tourists.",
                skills: ["HTML","CSS","Bootstrap","JavaScript","Firebase"],
                allDataUrl:"https://www.walkandtrot.in/"
            },
            {
                id: 5,
                imageUrl:"sreerevathisteels-bg.png",
                title:"Sree Revathi Steels",
                description:"This is a comprehensive platform that offers a wide range of high-quality iron and steel products to customers. The website features an easy-to-use product catalog with detailed information and specifications of the products, making it easy for customers to find what they are looking for.",
                skills: ["HTML","CSS","Bootstrap","JavaScript","Firebase","Ajax"],
                allDataUrl:"https://sreerevathisteels.com/"
            },
            {
                id: 6,
                imageUrl:"sgarages-bg.png",
                title:"S-Garages",
                description:"This website caters to the needs of car owners who demand exceptional detailing services for their vehicles. With a user-friendly interface and intuitive design, the website offers a comprehensive range of services, from basic wash to full exterior and interior detailing for cars and motorcycles.",
                skills: ["HTML","CSS","JavaScript","Bootstrap"],
                allDataUrl:"http://sgarages.com/"
            },
        ],
        designData: [
            {
                id: 1,
                imageUrl:"a-plus-card-bg.jpg",
                title:"A-Plus Designs",
                description:"Eye-catching amazon marketing e-pamphlets designed to be placed on the Amazon product page.",
                allDataUrl:"https://drive.google.com/drive/folders/1dKEXFHdfWW83Vj9mKSbPbocZWgDq9g-i"
            },
            {
                id: 2,
                imageUrl:"banner-design-bg.jpeg",
                title:"Banner Designs",
                description:"Landing page carousel banners created for various seasonal sale promotions.",
                allDataUrl:"https://drive.google.com/drive/folders/1lMbf3hVc_peVEhDgsFwgweJn4p1wM-Ap"
                
            },
            {
                id: 3,
                imageUrl:"callout-bg.jpg",
                title:"Callout Designs",
                description:"These are some of the collections of infographics and social media posts designed to showcase multiple pieces of information.",
                allDataUrl:"https://drive.google.com/drive/folders/1Os9afM2LUQkol1Y0idTdmKfnSSirGcrw"
            },
            {
                id: 4,
                imageUrl:"menu-design-bg.jpeg",
                title:"Restuarant Menu Designs",
                description:"Few restaurant menu card designs created based on my client's requirements.",
                allDataUrl:"https://drive.google.com/drive/folders/1tE8p3IGmnvTgxDhToJbLE_OWpi7k3lop"
            },
            {
                id: 5,
                imageUrl:"mobile-app-design-bg.jpg",
                title:"Mobile App Designs",
                description:"Modern day ravishing mobile app user interfaces created for day to day android and iOS applications.",
                allDataUrl:"https://drive.google.com/drive/folders/10h6DyQa_9cEnN9QneIBmDgd4imAIZZd1"
            },
            {
                id: 6,
                imageUrl:"product-showcase-bg.jpeg",
                title:"Product Showcase Video",
                description:"Videos are always the show stealers than any static designs. These are some collection of product infomercials.",
                allDataUrl:"https://drive.google.com/drive/folders/1AFFtggYEdArSgaSB1XqAQPzS18MHjL9p"
            }
    
        ],
        allSkillsData: [
            {
                confidentSkills:["JavaScript.svg","CSS3.svg","Figma.svg","Git.svg","HTML.svg","MySql.svg","Python.svg","React.svg","Bootstrap.svg","TailwindCSS.svg","Adobe-XD.svg"],
                basicConfidentSkills:["Docker.svg","Firebase.svg","Kubernets.svg","NodeJs.svg","SASS.svg","Travis-CI.svg","TypeScript.svg"]
            }
        ]
    }
}

export default exportedObject;