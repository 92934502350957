import React from 'react'

export default function Footer() {
  return (
    <footer className='mb-4'>
        <div className='text-center text-xs text-stone-200 font-spaceMono hover:text-lime-100'>
            &#169; Designed & Built by Gokul Kamalakannan
        </div>
    </footer>
  )
}
