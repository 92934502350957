import React from 'react'


export default function BasicSkills(props) {

  const listingBasicSkills = props.listSkill.basicConfidentSkills.map(feedSkill =>{
    return( 
        // <button type="button" className="py-2 mb-2 mr-2 px-2 text-sm font-medium text-lime-100 
        // bg-transparent rounded-lg border border-lime-100 dark: dark:bg-gray-800 
        // dark:text-gray-400 dark:border-gray-600"  key={feedSkill}>{feedSkill}</button>
        <span className='inline-block overflow-x-visible' key={feedSkill}>
          <img className="w-16 mx-12" src={require(`../images/${feedSkill}`)} alt=''/>
          {feedSkill.split('.')[0]}
        </span>
   )
 })

  return (
    <div className='overflow-x-visible'>{listingBasicSkills}</div>
  )
}
