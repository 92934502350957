import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Companies() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section>
      <Box sx={{ width: 'auto', mx:'1rem' }}>
        <Box sx={{ borderBottom: 2, borderColor: '#FBF8BE'}}>
          <Tabs
              TabIndicatorProps={{sx:{backgroundColor:'#FBF8BE', height: 4}}} 
              sx={{
                '& button':{color: '#DADADA',textTransform: "none", fontFamily:'Space Mono',fontSize:"1rem"},
                '& button:active':{color: '#FBF8BE'},
                '& button.Mui-selected':{color: '#DADADA'}
              }} 
              value={value} onChange={handleChange} aria-label="basic tabs example" centered fullwidth="true">
            <Tab label="Maxffort"{...a11yProps(0)} style={{minWidth:"33%"}}/>
            <Tab label="Envelor"{...a11yProps(1)} style={{minWidth:"33%"}}/>
            <Tab label="Nokia"{...a11yProps(2)} style={{minWidth:"33%"}}/>
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
        <Typography variant="subtitle1" className='text-xl font-ubuntu text-stone-400'>
          Web Developer/Designer <span className='text-lime-100'>@ Maxffort Technologies Private Limited</span>
        </ Typography>
        <span className='text-sm font-spaceMono text-stone-400 mt-2'>Sept 2017 - May 2019</span>
        <ul className="mt-3 list-disc list-inside text-stone-200">
          <li>Code, test and debug responsive interactive websites using web technologies such as React, HTML,
              CSS, JavaScript, Python, and relational database MySQL.</li>
          <li>Illustrate design ideas using storyboards, mock-ups, prototyping, user flows, process flows, user
              experience and sitemaps with XD and create UX designs.</li>
          <li>Developing and maintaining visual design wireframes and specifications for hybrid-responsive web
              applications and interfaces.</li>
          <li>Been part of a concise and an efficient agile software development team, performing regular scrum
              meetings, assisted in project scope assessment, and creating delivering reports within SLA.</li>
        </ul>
        </TabPanel>
        <TabPanel value={value} index={1}>
        <Typography variant="subtitle1" className='text-xl font-ubuntu text-stone-400'>
        Web Developer/Designer – Design Generalist <span className='text-lime-100'>@ Envelor Corporation</span>
        </Typography>
        <span className='text-sm font-spaceMono text-stone-400 mt-2'>May 2019 – Aug 2022</span>
        <ul className="list-disc list-inside mt-3 text-stone-200 ">
          <li>Assisted in creating eCommerce websites in Shopify and product showcase websites in WordPress.</li>
          <li>Conducted a study for brand standardizing eCommerce websites with online accessibility standards
              followed consistently based on Baymard design guidelines.</li>
          <li>Hands on experience with SEO works including keyword research, backlinks, adding tags and meta
              descriptions, Shorting URL’s and with popular SEO tools such as Google Lighthouse, Page Speed
              Insights and Google Analytics.</li>
          <li>Collaborated for projects with teams both onshore and offshore team for actively making
              corrections, fixing bugs and unit testing on websites, and improving Google search ranking.</li>
          <li>Created various python scripts that help to do repeated image editing procedures, which saved
              60% of the time needed to complete the task for that company.</li>
          <li>Identified issues in Odoo ERP system and helped the team to configure it for efficient business flow.</li>
        </ul>
        </TabPanel>
        <TabPanel value={value} index={2}>
        <Typography variant="subtitle1" className='text-xl font-ubuntu text-stone-400'>
        Software Developer Intern <span className='text-lime-100'>@ Nokia</span>
        </Typography>
        <span className='text-sm font-spaceMono text-stone-400 mt-2'>Sept 2022 – Dec 2022</span>
        <ul className="list-disc list-inside mt-3 text-stone-200">
          <li>Engaged in writing and maintaining quality code, peer code review for testing and rewriting with
              proactively resolving bugs in the front-end codebase in an Agile team environment, resulting in
              improved user experience.</li>
          <li>Collaborated with other developers to ensure consistency and maintainability of the web
              components across the Nokia application.</li>
          <li>Brainstorming web page ideas with UX designers and cross-functional team using wireframing
              tools.</li>
          <li>Built front-end data validation tasks using React, improving the accuracy and reliability of data
              inputs.</li>
          <li>Contributed to development of automated log backups to Elasticsearch and documented the
              manual procedures for the same using Kibana dashboard.</li>
        </ul>
        </TabPanel>
      </Box>
    </section>
  );
}