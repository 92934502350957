import React from 'react'

export default function Button(props) {
  return (
    <button className="bg-transparent tracking-widest hover:bg-lime-100 text-lime-100 font-spaceMono hover:text-gray-600
    py-1 px-6 border border-lime-100 hover:border-transparent md:ml-8 rounded duration-500">
        {props.children}
  </button>
  )
}
