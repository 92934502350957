import React from 'react'


export default function Hero() {

  const handleClickScroll = () => {
    const element = document.getElementById('projects');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (

    <div className='hero--component'>
      <div className='flex flex-col text-center mt-32 px-8 sm:mt-56 md:mt-48'>
          <h1 className='text-sm font-spaceMono text-lime-100 '>Hi there! My name is</h1>
          <h2 className='text-3xl font-ubuntu text-stone-200'>Gokul Kamalakannan.</h2>
          <h2 className='text-3xl font-ubuntu text-stone-400'>I design, build components for the web.</h2>
          <div className='px-4 sm:px-20 md:px-60 lg:px-80 xl:px-95'>
              <h3 className='font-ubuntu mt-14 mb-10  text-stone-200'>I am a talented web developer and designer with 
              a passion for creating beautiful, functional websites. I combine creativity and attention to detail
              to deliver custom web solutions that exceed expectations.</h3>
              <button className="bg-transparent hover:bg-lime-100 text-lime-100 font-spaceMono font-semibold hover:text-stone-400
              py-2 px-4 border border-lime-100 hover:border-transparent rounded" onClick={handleClickScroll}>
                  See My Projects
              </button>
          </div>
      </div>

        <div className='animate-bounce text-center mt-14 md:mt-30'>
          <ion-icon name="caret-down-circle-outline"></ion-icon> 
        </div>

    </div>
  )
}
