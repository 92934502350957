import React from 'react'
import { useForm, ValidationError } from '@formspree/react';

function MyForm() {
    const [state, handleSubmit] = useForm("xknaokge");
    if (state.succeeded) {
      return <div className='font-ubuntu text-stone-200'>Your message for sent succesfully!</div>;
    }
    return (
      <form onSubmit={handleSubmit}>
                    <div className="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <input
                                type="text"
                                className="block w-full bg-transparent border border-lime-100 font-ubuntu text-stone-200 sm:text-sm rounded-lg 
                                focus:ring-lime-100 dark:text-white"
                                placeholder="Name"
                                // onChange={handleChange}
                                name="firstName"
                                // value={formData.firstName}
                            />
                        </div>
                        <div>
                            <input
                                type="email"
                                className="block w-full bg-transparent border border-lime-100 font-ubuntu text-stone-200 sm:text-sm rounded-lg 
                                focus:ring-lime-100 dark:text-white"
                                placeholder="Email"
                                // onChange={handleChange}
                                name="email"
                                // value={formData.email}
                            required/>
                            <ValidationError 
                                prefix="Email" 
                                field="email"
                                errors={state.errors}
                            />
                        </div>
                    </div>
                    <div className="mb-6">
                        <textarea 
                            // value={formData.message}
                            className="bg-transparent border block w-full h-32 border-lime-100 font-ubuntu text-stone-200 sm:text-sm rounded-lg 
                            focus:ring-lime-100 dark:text-white"
                            placeholder="Message..."
                            // onChange={handleChange}
                            name="message"
                        />
                        <ValidationError 
                            prefix="Message" 
                            field="message"
                            errors={state.errors}
                        />
                    </div>
                    <button type="submit" disabled={state.submitting} className="bg-transparent w-full tracking-widest hover:bg-lime-100 text-lime-100 font-spaceMono hover:text-gray-600
    py-1 px-6 border border-lime-100 hover:border-transparent rounded duration-500">Submit</button>
                </form>
    )
  }

export default function Contact() {

  return (
    <section id='contact' className='mt-32 mb-16'>
        <div className="flex mt-14 items-center justify-center w-full mb-32">
            <hr className="w-52 ml-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="px-3 text-xs md:text-lg font-spaceMono font-medium 
            text-stone-400 dark:text-white dark:bg-gray-900">
                <span className='text-lime-100'>4.</span> Connect with me.</span>
            <hr className=" w-52 mr-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
        </div>

        <div className='grid md:flex md:flex-row md:justify-around'>
            <div >
            <h6 className="uppercase tracking-widest underline text-2xl text-lime-100 font-ubuntu font-normal mb-4 flex justify-center md:justify-start">
            Say Hello!
            </h6>
            <div className="flex items-center justify-center md:justify-start mb-4">
                <a className="flex items-center justify-center md:justify-start mb-4 mr-4" href="mailto:gokulkungumaprakash@gmail.com" target="_blank" rel="noreferrer" >
                    <svg className='fill-lime-100' width="25" height="25" viewBox="0 0 50 50"><path d="M14 4C8 4 4 8 4 14v22c0 6 4 10 10 10h22c6 0 10-4 10-10V14c0-6-4-10-10-10H14zm-1 12h25L28 26h-6L12 
                    16h1zm-2 1 8 8-8 8V17zm28 0v16l-8-8 8-8zm-19 9 1 1a6 6 0 0 0 8 0l1-1 8 8H12l8-8z"/></svg>
                    <p className="ml-4 text-sm text-stone-200 font-spaceMono">gokulkungumaprakash@gmail.com</p>
                </a>
            </div>
            <div className="flex items-center justify-center md:justify-start mb-4">
                <a className="flex items-center justify-center md:justify-start mb-4 mr-4" href="https://github.com/gokul3k" target="_blank" rel="noreferrer">
                <svg className='fill-lime-100' width="25" height="25" viewBox="0 0 50 50"><path d="m18 47 1-2v-6h-4l-3-2-3-4 1-1 2 2 4 2 4-1 4-2c-6 0-9-2-11-5l-9 1v-1l9-1-1-1H4v-1h8v-1l2-5c-1-2-2-6 0-7 
                3 0 4 1 5 2l6-1 6 1c1-1 2-2 5-2 2 1 1 5 0 7l2 5v1h8v1h-8l-1 1 9 1v1l-9-1c-2 3-5 5-11 5 3 0 5 
                4 5 7v5l1 2a23 23 0 1 0-14 0z"/></svg>
                    <p className="ml-4 text-sm text-stone-200 font-spaceMono">github.com/gokul3k</p>
                </a>
            </div>
            <div className="flex items-center justify-center md:justify-start mb-4">
                <a className="flex items-center justify-center md:justify-start mb-4 mr-4" href="https://t.me/gokul3k" target="_blank" rel="noreferrer">
                <svg className='fill-lime-100' width="25" height="25" viewBox="0 0 50 50"><path d="m46 7-3-1A16123 16123 0 0 0 3 25c0 2 2 3 2 3l9 3 3 11 2 2h2l5-5 9 6v1h3l2-2 7-34-1-3zM22 32l-3 8-3-10 23-17-17 19z"/></svg>
                    <p className="ml-4 text-sm text-stone-200 font-spaceMono">t.me/gokul3k</p>
                </a>
            </div>
            <div className="flex items-center justify-center md:justify-start mb-4">
                <a className="flex items-center justify-center md:justify-start mb-4" href="https://www.linkedin.com/in/gokul96/" target="_blank" rel="noreferrer">
                <svg className='fill-lime-100' width="25" height="25" viewBox="0 0 50 50"><path d="M41 4H9C6 4 4 6 4 9v32c0 3 2 5 5 5h32c3 0 5-2 5-5V9c0-3-2-5-5-5zM17 20v19h-6V20h6zm-6-6c0-1 
                1-2 3-2s3 1 3 2c0 2-1 3-3 3s-3-1-3-3zm28 25h-6V29c0-2-1-4-3-4h-1c-2 0-3 2-3 4v10h-6V20h6v3s2-3 6-3 7 3 7 8v11z"/></svg>
                    <p className="ml-4 text-sm text-stone-200 font-spaceMono">linkedin.com/in/gokul96/</p>
                </a>
            </div>
        
            </div>
            <div className='mx-16 md:mx-0'>
                <MyForm />
            </div>
        </div>
    </ section>
    
  )
}
