
import React from 'react'
import Companies from './Companies';
import ConfidentSkills from './ConfidentSkills';
import BasicSkills from './BasicSkills';
import { nanoid } from 'nanoid';

export default function Experience(props) {

  const confidentSkillsCard = props.text.data.allSkillsData.map(listSkill =>{
    return (
        <ConfidentSkills key={nanoid} listSkill = {listSkill} />
    )
  })
  
  const basicSkillsCard = props.text.data.allSkillsData.map(listSkill =>{
    return (
        <BasicSkills key={nanoid} listSkill = {listSkill} />
    )
  })
  
  
  return (
    <section id='experience' className='mt-32 mx-1.5 sm:mx-12 mb-32'>
        <div className="flex mt-14 items-center justify-center w-full mb-32">
            <hr className="w-52 ml-4 h-px  text-center bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="px-3 text-xs md:text-lg font-spaceMono font-medium 
            text-stone-400 dark:text-white dark:bg-gray-900">
                <span className='text-lime-100'>2.</span> 
                Languages, Skills & Experience.
            </span>
            <hr className=" w-52 mr-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
        </div>

        <h2 className='text-xl text-center font-ubuntu text-stone-400'>
            Languages and Frameworks I feel confident in
        </h2>

        <div className="relative flex justify-center text-white text-center mb-32">
            <div className="py-12 overflow-x-visible animate-marquee hover:pause whitespace-nowrap">
            {confidentSkillsCard}
            </div>
        </div>
    
        <h2 className='text-xl text-center font-ubuntu text-stone-400'>
        Technologies I have exposure and basic experience with
        </h2>

        <div className="relative flex justify-center text-white text-center mb-32">
            <div className="py-12 overflow-x-visible animate-marquee hover:pause whitespace-nowrap">
                {basicSkillsCard}
            </div>
        </div>

    <Companies />

  </section> 

  )
}
