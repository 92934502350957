import React from 'react'
import Card from './Card'
import DesignCard from './DesignCard'

export default function Projects(props) {


  const cards = props.text.data.projectData.map(item => {
    return (
        <Card 
          key={item.id}
          item={item}
        />
    )
})        

const designCard = props.text.data.designData.map(item => {
  return (
      <DesignCard 
        key={item.id}
        item={item}
      />
  )
})  

  return (
    <>
        <div id='projects' className="flex mt-16 items-center justify-center w-full mb-32">
            <hr className="w-52 ml-4 h-px  text-center bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="px-3 text-xs md:text-lg font-spaceMono font-medium 
            text-stone-400 dark:text-white dark:bg-gray-900">
                <span className='text-lime-100'>3.</span> 
                Projects I have developed & contributed.
            </span>
            <hr className=" w-52 mr-4 h-px bg-gray-200 border-0 dark:bg-gray-700" />
        </div>

    <section className='flex flex-nowrap gap-3 overflow-x-auto px-10 2xl:justify-center'>
      {cards}
    </section>

    <div className="flex mt-16 items-center justify-center w-full mb-16">
      <span className="px-3 text-base md:text-xl font-spaceMono font-medium 
              text-stone-400 dark:text-white dark:bg-gray-900">
            <span className='text-lime-100 font-medium'># </span>Some of my design projects
      </span>
    </div>

    <section className='flex flex-nowrap gap-3 overflow-x-auto px-10 2xl:justify-center'>
      {designCard}
    </section>

    </>
  )
}
