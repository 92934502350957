import React from 'react'

export default function DesignCard(props) {
  return (

  <div className="w-fit flex flex-none flex-col items-center bg-transparent border border-lime-100 rounded-lg shadow md:flex-row md:max-w-xl dark:border-gray-700 dark:bg-gray-800">
      <img className="object-cover w-full rounded-t-lg h-96 md:rounded-none md:rounded-l-lg" 
      src={require(`../images/${props.item.imageUrl}`)}  alt="" />
      <div className="flex flex-col justify-between p-4 leading-normal">
          <h5 className="mb-4 text-2xl font-ubuntu font-bold tracking-tight text-stone-200 dark:text-white">{props.item.title}</h5>
          <h3 className="mb-4 font-ubuntu text-stone-200 font-thin text-sm dark:text-gray-400">{props.item.description}</h3>
          <a href={props.item.allDataUrl} target="_blank" rel="noopener noreferrer"><button type="button" className="py-2 w-full mb-2 mr-2 px-2 text-sm font-medium text-lime-100 
            bg-transparent rounded-lg border border-lime-100 dark: dark:bg-gray-800 
            dark:text-gray-400 dark:border-gray-600" >View All</button></a>
      </div>
  </div>

  )
}
