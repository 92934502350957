import React from 'react'



export default function Card(props) {

  const skillSet = props.item.skills.map(item =>{
    return (
      <button type="button" className="py-2 mb-2 mr-2 px-2 text-sm font-medium text-lime-100 
      bg-transparent rounded-lg border border-lime-100 dark: dark:bg-gray-800 
      dark:text-gray-400 dark:border-gray-600"  key={item}>{item}</button>
    )
  })

  return ( 
    <div className="w-72 overflow-hidden flex flex-none flex-col bg-transparent border border-lime-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
        <a href={props.item.allDataUrl} target="_blank" rel="noopener noreferrer">
            <img className="rounded-t-lg w-full hover:scale-105 ease-in duration-100 " src={require(`../images/${props.item.imageUrl}`)} alt=''/>
        </a>
        <div className="p-5">
            <a href={props.item.allDataUrl} target="_blank" rel="noopener noreferrer">
                <h5 className="mb-4 text-2xl font-ubuntu font-bold tracking-tight text-stone-200 dark:text-white hover:text-white">{props.item.title}</h5>
            </a>
            <p className="mb-4 font-ubuntu text-stone-200 font-thin text-xs dark:text-gray-400">{props.item.description}</p>
            <div>
              {skillSet}
            </div>
        </div>
    </div>

  )
}
